import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";
import {start} from "@rails/activestorage/src";

$(document).ready(function () {
    getConstructionSelectedOptions();
    getAreaIdSelectedOptions();
});

$(function () {
    var $statusElm = $("#job-status");
    var MAX_FAILED_REQUEST = 10;
    var failedRequestNumber = 0;
    var $progressElm = $("div[role=progressbar]", $statusElm);
    var MAIN_END_POINT = $(".main-layout-wrap").data("main-endpoint");

    $(".export-excel-btn.worker-charge").on("click", function () {
        var params = $("#worker_charge_filter_wrapper").data("params");

        $.ajax({
            url: "/worker_charges/export",
            dataType: "json",
            data: params,
        })
            .done(function (response, status, xhrOpts) {
                if (status === "success") {
                    var jobId = response.jid;
                    var intervalName = "job_" + jobId;
                    $statusElm.toggleClass("hidden");

                    window[intervalName] = setInterval(function () {
                        getJobStatus(jobId, intervalName);
                    }, 5000);
                }
            })
            .fail(function (err) {
            });
    });

    function getJobStatus(jobId, intervalName) {
        var jobStatusUrl = "/worker_charges/job_status";
        $.ajax({
            method: "POST",
            url: jobStatusUrl,
            data: {
                job_id: jobId,
            },
        })
            .done(function (response, status, xhrOpts) {
                if (status === "success") {
                    var percentage = response.percentage;
                    setProgressPercentage($progressElm, percentage || 0);

                    if (response.status === "complete") {
                        setProgressPercentage($progressElm, "100");
                        setTimeout(function () {
                            deleteIntervalJob(intervalName);
                            setProgressPercentage($progressElm, "0");

                            $statusElm.toggleClass("hidden");
                            var exportUrl =
                                "/worker_charges/export_download.xlsx" + "?id=" + jobId + "&export_model=worker_charge";

                            window.location.href = exportUrl;
                        }, 500);
                    } else if (
                        (response.status === "retrying" || response.status === "queued") &&
                        response.percentage == null
                    ) {
                        failedRequestNumber += 1;
                    }
                    if (failedRequestNumber >= MAX_FAILED_REQUEST) {
                        $statusElm.toggleClass("hidden");
                        deleteIntervalJob(intervalName);
                    }
                } else {
                    deleteIntervalJob(intervalName);
                }
            })
            .fail(function (err) {
                deleteIntervalJob(intervalName);
            });
    }

    function deleteIntervalJob(intervalName) {
        clearInterval(window[intervalName]);
        delete window[intervalName];
        failedRequestNumber = 0;
    }

    function setProgressPercentage($progressElm, percentage) {
        $progressElm
            .attr("aria-valuenow", percentage)
            .css("width", percentage + "%")
            .text(percentage + "%");
    };

    function api(field, per_page = 10) {
        return `${MAIN_END_POINT}search_construction_fields?pageSearch=worker_charges&field=${field}&current_page=1&per_page=${per_page}`;
    }

    function searchMultiple(field, api, multiple) {
        $(`.${field}`).select2({
            ajax: {
                url: api,
                dataType: "json",
                quietMillis: 100,
                data: function (params) {
                    return {
                        keyword: params.term,
                    };
                },
                processResults: function (data) {
                    return {results: data};
                },
            },
            multiple: true,
            language: "ja",
            closeOnSelect: false,
            tags: multiple,
            cache: true
        });
    };

    function searchSingle(field, api, multiple) {
        $(`.${field}`).select2({
            ajax: {
                url: api,
                dataType: "json",
                quietMillis: 100,
                data: function (params) {
                    return {
                        keyword: params.term,
                    };
                },
                processResults: function (data) {
                    return {results: data};
                },
            },
            multiple: false,
            language: "ja",
            closeOnSelect: true,
            tags: true,
            cache: true
        });
    };

    searchMultiple("js-worker-construction-code", api("construction_code", 20), true);
    searchSingle("js-worker-construction-code-single", api("construction_code", 20), true);

    $(".worker-charge-search-btn").click(function () {
        var constructionSelectedOptions = [];
        var areaOptions = [];

        $(".js-worker-construction-code option").each(function () {
            constructionSelectedOptions.push({id: $(this).val(), text: $(this).text(), selected: true});
        });

        var areaOptionsSelected = $("#select2-area_id-container li");
        if (areaOptionsSelected.length > 0) {
            $(areaOptionsSelected).each(function () {
                var areaOption = $(".worker_charge_area_id").find(`option:contains(${$(this).attr("title")})`);
                areaOptions.push({id: areaOption.val(), text: areaOption.text(), selected: true});
            });
            localStorage.setItem("areaSelectedOptions", JSON.stringify(areaOptions));
        } else {
            localStorage.removeItem("areaSelectedOptions");
        }
        ;

        localStorage.removeItem("constructionSelectedOptions");
        localStorage.setItem("constructionSelectedOptions", JSON.stringify(constructionSelectedOptions));
    });

    $(document).on("click", ".select2-selection__choice__remove", function () {
        var elTitle = $(this).closest("li").attr("title");
        var constructionCode = elTitle.split(" ")[0];
        $(".js-worker-construction-code").find(`option:contains(${constructionCode})`).remove();
        $(".js-worker-construction-code").trigger("change");
    })

    $(document).on("click", "a, input[type=submit]", function () {
        if (!$(this).hasClass("workload-keep-storage")) {
            localStorage.removeItem("constructionSelectedOptions");
        }
    });

    $(document).on("click", ".close, .js-btn-cancel", function () {
        $(this).closest('.modal').hide();
        $(".js-worker-construction-code-single").find('option').remove();
        $(".js-worker-construction-code-single").trigger("change");
        $('input').removeClass('error-input');
        $('textarea').removeClass('error-input');
        $('.js-internal-name').val('');
    });

    $(document).on("click", ".js-data-clickable", function (e) {
        var canManageData = $(this).data('can-manage-data');
        if (!canManageData) {
            return false;
        }

        // $(this).css('background', '#f6c042');
        var workerName = $(this).data('worker-name');
        var workerId = $(this).data('worker-id');
        var dateFormat = $(this).data('date-format');
        var ctCode = $(this).data('ct-code');
        var nameInternal = $(this).data('name-internal');
        var dateFormatJp = $(this).data('date-format-jp');
        var startDateJp = $(this).data('start-date-jp');
        var endDateJp = $(this).data('end-date-jp');
        var workerData = $(this).closest('.item-group-td').data('worker-construction');
        var currentStartDate = $(this).closest('.items-group').data('current-start-date');
        var ctType = $(this).closest('.items-group').data('ct-type');
        var idAlias = $(this).closest('.items-group').data('id-alias');
        $('.hidden-data-block .js-worker-id').val('').val(workerId);
        $('.hidden-data-block .js-worker-name').val('').val(workerName);
        $('.hidden-data-block .js-construction-date').val('').val(dateFormat);
        $('.hidden-data-block .js-ct-code').val('').val(ctCode);
        $('.hidden-data-block .js-internal-name').val('').val(nameInternal);
        $('.hidden-data-block .js-date-format-jp').val('').val(dateFormatJp);
        $('.hidden-data-block .js-edit-start-at').val('').val(startDateJp);
        $('.hidden-data-block .js-edit-end-at').val('').val(endDateJp);
        $('.hidden-data-block .js-edit-worker-data').val('').val(workerData);
        $('.hidden-data-block .js-current-start-date').val('').val(currentStartDate);
        $('.hidden-data-block .js-id-alias').val('').val(idAlias);
        var parentCtType = $(this).closest('.items-group').data('ct-type');
        if ($(this).hasClass('bg-cyan') || parentCtType == 'cyan'){
            ctType = null;
        }
        $('.hidden-data-block .js-ct-type').val('').val(ctType);

        var currentData = $(this).closest('.items-group');
        var currentWorkerId = $(currentData).data('worker-id');
        var currentWorkerName = $(currentData).data('worker-name');
        var currentCtCode = $(currentData).data('ct-code');
        var currentNameInternal = $(currentData).data('name-internal');
        $('.hidden-data-block .js-current-worker-id').val('').val(currentWorkerId);
        $('.hidden-data-block .js-current-worker-name').val('').val(currentWorkerName);
        $('.hidden-data-block .js-current-ct-code').val('').val(currentCtCode);
        $('.hidden-data-block .js-current-internal-name').val('').val(currentNameInternal);

        if ($(this).hasClass('bg-yl') || $(this).hasClass('bg-cyan')) {
            if ($(this).hasClass('js-data-manual')) {
                $('#existFieldChoseActionModal').find('.has_data').show();
                $('#existFieldChoseActionModal').find('.has_no_data').hide();
            } else {
                $('#existFieldChoseActionModal').find('.has_data').hide();
                $('#existFieldChoseActionModal').find('.has_no_data').show();
            }
            $('#existFieldChoseActionModal').show();
            $('#existFieldChoseActionModal').css('opacity', 1);
        } else {
            $('#blankFieldChoseActionModal').show();
            $('#blankFieldChoseActionModal').css('opacity', 1);
        }
    });

    $(document).on("click", ".js-btn-create-exist-confirm", function (e) {
        e.preventDefault();
        $('.error-msg').hide();
        $('.js-exist-start-at-input').val('');
        $('.js-exist-end-at-input').val('');
        // var workerName = $('.hidden-data-block .js-current-worker-name').val();
        // var dateFormat = $('.hidden-data-block .js-construction-date').val();
        // var ctCode = $('.hidden-data-block .js-current-ct-code').val();

        // var dateParts = dateFormat.split('/');
        // var dateFormatJp = dateParts[0] + "年" + dateParts[1] + "月" + dateParts[2] + "日";

        // var confirmText = `${workerName}の${dateFormatJp}の${ctCode}を登録してもよろしいでしょうか。`;
        // $('#confirmEditModal .confirm-text').html('').html(confirmText);

        $(this).closest('.modal').hide();
        $('#confirmEditModal').show();
        $('#confirmEditModal').css('opacity', 1);
    });

    $(document).on("click", ".js-btn-edit", function (e) {
        e.preventDefault();
        $('input').removeClass('error-input');
        $('.error-msg').hide();
        var workerId = $('.hidden-data-block .js-worker-id').val();
        var workerName = $('.hidden-data-block .js-worker-name').val();
        var ctCode = $('.hidden-data-block .js-ct-code').val();
        var nameInternal = $('.hidden-data-block .js-internal-name').val();
        var startDate = $('.hidden-data-block .js-edit-start-at').val();
        var endDate = $('.hidden-data-block .js-edit-end-at').val();
        $('.js-ct-start-at-input').val('').val(startDate);
        $('.js-ct-end-at-input').val('').val(endDate);
        $('.js-ct-start-at').val('').val(startDate);
        $('.js-ct-end-at').val('').val(endDate);
        $("#editConstructionModal .js-construction-code").find('option')
            .remove().end()
            .append(`<option value=${ctCode}>${ctCode}</option>`)
            .val(ctCode)
            .trigger("change");
        // var dateParts = dateFormat.split('/');
        $("#editConstructionModal .js-worker-id").val(workerId);
        $("#editConstructionModal .js-worker-name").val(workerName);
        $("#editConstructionModal .js-internal-name").val(nameInternal);
        $(this).closest('.modal').hide();
        $('#editConstructionModal').css('opacity', 1).show();
    });

    $(document).on("click", ".js-btn-edit-submit", function (e) {
        e.preventDefault();
        $(this).addClass('disabled');
        $('input').removeClass('error-input');
        $('.error-msg').hide();
        $('.js-start-at-error').hide();
        $('.js-end-at-error').hide();

        var modal = $('#editConstructionModal');
        var constructionCode = modal.find('.js-construction-code').val();
        var internalName = modal.find('.js-internal-name').val();
        var workerId = modal.find('.js-worker-id').val();
        var workerName = modal.find('.js-worker-name').val();
        var startAt = modal.find('.js-ct-start-at').val();
        var endAt = modal.find('.js-ct-end-at').val();
        var sourceCtCode = $('.hidden-data-block .js-current-ct-code').val();
        var workerData = $('.hidden-data-block .js-edit-worker-data').val();
        var idAlias = $('.hidden-data-block .js-id-alias').val();
        $.ajax({
            url: "/worker_charges/edit_construction_manual",
            dataType: "json",
            method: "POST",
            data: {
                id_alias: idAlias, construction_code: constructionCode, internal_name: internalName,
                worker_id: workerId, worker_name: workerName, start_at: startAt, end_at: endAt,
                source_construction_code: sourceCtCode, worker_data: workerData
            },
        }).done(function (response, status, xhrOpts) {
            if (response.hasOwnProperty('construction_code')) {
                $('.js-construction-code').addClass('error-input');
                $('.js-ct-code-error').show();
                $('.js-ct-code-error').html('').html(response.construction_code);
                setTimeout(function() {
                    $('.js-ct-code-error').hide();
                    $('.js-construction-code').removeClass('error-input');
                }, 3000);
                $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('start_at')) {
                $('.js-ct-start-at-input').addClass('error-input');
                $('.js-start-at-error').show();
                $('.js-start-at-error').html('').html(response.start_at);
                setTimeout(function() {
                    $('.js-start-at-error').hide();
                    $('.js-ct-start-at-input').removeClass('error-input');
                }, 3000);
                $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('end_at')) {
                modal.find('.js-ct-end-at-input').addClass('error-input');
                $('.js-end-at-error').show();
                $('.js-end-at-error').html('').html(response.end_at);
                setTimeout(function() {
                    $('.js-end-at-error').hide();
                    modal.find('.js-ct-end-at-input').removeClass('error-input');
                }, 3000);
                $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('name_internal')) {
                modal.find('.js-internal-name').addClass('error-input');
                $('.js-name-internal-error').show();
                $('.js-name-internal-error').html('').html(response.name_internal);
                setTimeout(function() {
                    $('.js-name-internal-error').hide();
                    modal.find('.js-internal-name').removeClass('error-input');
                }, 3000);
                $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
                return false
            }
            $('.js-start-at-error').hide();
            $('.js-end-at-error').hide();
            $('.js-name-internal-error').hide();
            location.reload();
        }).fail(function (err) {
            console.log(err);
        });
    });

    $(document).on("click", ".js-btn-create", function (e) {
        e.preventDefault();
        $('.error-msg').hide();

        var workerName = $('.hidden-data-block .js-worker-name').val();
        var workerId = $('.hidden-data-block .js-worker-id').val();
        var dateFormat = $('.hidden-data-block .js-construction-date').val();
        $('#createNewConstructionModal .js-worker-id').val('').val(workerId);
        $('#createNewConstructionModal .js-worker-name').val('').val(workerName);
        $('#createNewConstructionModal .js-construction-date').val('').val(dateFormat);
        $('#createNewConstructionModal .js-ct-start-at-input').val('');
        $('#createNewConstructionModal .js-ct-end-at-input').val('');
        $('#createNewConstructionModal .js-ct-start-at').val('');
        $('#createNewConstructionModal .js-ct-end-at').val('');

        $(this).closest('.modal').hide();
        $('#createNewConstructionModal').show();
        $('#createNewConstructionModal').css('opacity', 1);
    });

    $(document).on("click", ".js-btn-delete", function (e) {
        e.preventDefault();

        var workerName = $('.hidden-data-block .js-worker-name').val();
        var workerId = $('.hidden-data-block .js-worker-id').val();
        var dateFormat = $('.hidden-data-block .js-construction-date').val();
        var ctCode = $('.hidden-data-block .js-ct-code').val();
        var internalName = $('.hidden-data-block .js-internal-name').val();
        var dateFormatJp = $('.hidden-data-block .js-date-format-jp').val();
        $('#confirmDeleteModal .js-worker-id').val('').val(workerId);
        $('#confirmDeleteModal .js-worker-name').val('').val(workerName);
        $('#confirmDeleteModal .js-construction-date').val('').val(dateFormat);
        $('#confirmDeleteModal .js-ct-code').val('').val(ctCode);
        $('#confirmDeleteModal .js-internal-name').val('').val(internalName);

        var confirmText = `${workerName}の${dateFormatJp}の${ctCode}を削除してもよろしいでしょうか。`
        $('#confirmDeleteModal .confirm-text').html(confirmText);
        $(this).closest('.modal').hide();
        $('#confirmDeleteModal').show();
        $('#confirmDeleteModal').css('opacity', 1);
    });

    $(document).on("click", ".js-btn-submit", function (e) {
        e.preventDefault();
        $(this).addClass('disabled');
        $('input').removeClass('error-input');
        $('.error-msg').hide();
        $('.js-start-at-error').hide();
        $('.js-end-at-error').hide();

        var modal = $('#createNewConstructionModal');
        var constructionCode = modal.find('.js-construction-code').val();
        var internalName = modal.find('.js-internal-name').val();
        var workerId = modal.find('.js-worker-id').val();
        var workerName = modal.find('.js-worker-name').val();
        // var constructionDate = modal.find('.js-construction-date').val();
        var startAt = modal.find('.js-ct-start-at').val();
        var endAt = modal.find('.js-ct-end-at').val();
        var workerData = $('.hidden-data-block .js-edit-worker-data').val();

        $.ajax({
            url: "/worker_charges/create_construction_manual",
            dataType: "json",
            method: "POST",
            data: {
                construction_code: constructionCode, internal_name: internalName, worker_id: workerId,
                worker_name: workerName, start_at: startAt, end_at: endAt, worker_data: workerData
            },
        }).done(function (response, status, xhrOpts) {
            if (response.hasOwnProperty('construction_code')) {
                $('.js-construction-code').addClass('error-input');
                $('.js-ct-code-error').show();
                $('.js-ct-code-error').html('').html(response.construction_code);
                setTimeout(function() {
                    $('.js-ct-code-error').hide();
                    $('.js-construction-code').removeClass('error-input');
                }, 3000);
                $('#createNewConstructionModal').find('.js-btn-submit').removeClass('disabled');
                return false
            }

            if (response.hasOwnProperty('start_at')) {
                $('.js-ct-start-at-input').addClass('error-input');
                $('.js-start-at-error').show();
                $('.js-start-at-error').html('').html(response.start_at);
                setTimeout(function() {
                    $('.js-start-at-error').hide();
                    $('.js-ct-start-at-input').removeClass('error-input');
                }, 3000);
                $('#createNewConstructionModal').find('.js-btn-submit').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('end_at')) {
                modal.find('.js-ct-end-at-input').addClass('error-input');
                $('.js-end-at-error').show();
                $('.js-end-at-error').html('').html(response.end_at);
                setTimeout(function() {
                    $('.js-end-at-error').hide();
                    modal.find('.js-ct-end-at-input').removeClass('error-input');
                }, 3000);
                $('#createNewConstructionModal').find('.js-btn-submit').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('name_internal')) {
                $('.js-internal-name').addClass('error-input');
                $('.js-name-internal-error').show();
                $('.js-name-internal-error').html('').html(response.name_internal);
                setTimeout(function() {
                    $('.js-name-internal-error').hide();
                    $('.js-internal-name').removeClass('error-input');
                }, 3000);
                $('#createNewConstructionModal').find('.js-btn-submit').removeClass('disabled');
                return false
            }
            $('.js-ct-code-error').hide();
            $('.js-start-at-error').hide();
            $('.js-end-at-error').hide();
            $('.js-name-internal-error').hide();
            location.reload();
        }).fail(function (err) {
            console.log(err);
        });
    });

    $(document).on("click", "#confirmEditModal .js-btn-submit-exists", function (e) {
        e.preventDefault();
        $(this).addClass('disabled');
        $('input').removeClass('error-input');
        $('.error-msg').hide();
        var modal = $('#confirmEditModal');
        var workerId = $('.hidden-data-block .js-current-worker-id').val();
        var workerName = $('.hidden-data-block .js-current-worker-name').val();
        var ctCode = $('.hidden-data-block .js-current-ct-code').val();
        var internalName = $('.hidden-data-block .js-current-internal-name').val();
        var startAt = modal.find('.js-exist-start-at').val();
        var endAt = modal.find('.js-exist-end-at').val();
        var workerData = $('.hidden-data-block .js-edit-worker-data').val();
        var currentStartDate = $('.hidden-data-block .js-current-start-date').val();
        var ctType = $('.hidden-data-block .js-ct-type').val() ? 'source_time' : null
        var idAlias = $('.hidden-data-block .js-id-alias').val();
        $.ajax({
            url: "/worker_charges/create_construction_manual",
            dataType: "json",
            method: "POST",
            data: {
                construction_code: ctCode, internal_name: internalName, worker_id: workerId,
                worker_name: workerName, status: 'exists', start_at: startAt, end_at: endAt,
                exist: true, worker_data: workerData, current_start_date: currentStartDate,
                ct_type: ctType, id_alias: idAlias
            },
        }).done(function (response, status, xhrOpts) {
            if (response.hasOwnProperty('all_date')) {
                $('.js-exist-start-at-input').addClass('error-input');
                $('.js-start-at-error').show();
                $('.js-start-at-error').html('').html(response.all_date);
                $('.js-end-at-error').show();
                $('.js-end-at-error').html('').html(response.all_date);
                setTimeout(function() {
                    $('.js-start-at-error').hide();
                    $('.js-end-at-error').hide();
                    $('.js-exist-start-at-input').removeClass('error-input');
                }, 3000);
                $('#confirmEditModal').find('.js-btn-submit-exists').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('start_at')) {
                $('.js-exist-start-at-input').addClass('error-input');
                $('.js-start-at-error').show();
                $('.js-start-at-error').html('').html(response.start_at);
                setTimeout(function() {
                    $('.js-start-at-error').hide();
                    $('.js-exist-start-at-input').removeClass('error-input');
                }, 3000);
                $('#confirmEditModal').find('.js-btn-submit-exists').removeClass('disabled');
                return false
            }
            if (response.hasOwnProperty('end_at')) {
                modal.find('.js-exist-end-at-input').addClass('error-input');
                $('.js-end-at-error').show();
                $('.js-end-at-error').html('').html(response.end_at);
                setTimeout(function() {
                    $('.js-end-at-error').hide();
                    modal.find('.js-exist-end-at-input').removeClass('error-input');
                }, 3000);
                $('#confirmEditModal').find('.js-btn-submit-exists').removeClass('disabled');
                return false
            }
            $('.js-start-at-error').hide();
            $('.js-end-at-error').hide();
            location.reload();
        }).fail(function (err) {});
    });

    $(document).on("click", ".js-btn-delete-ok", function (e) {
        e.preventDefault();

        var modal = $('#confirmDeleteModal');
        var workerId = modal.find('.js-worker-id').val();
        var workerName = modal.find('.js-worker-name').val();
        var constructionDate = modal.find('.js-construction-date').val();
        var ctCode = modal.find('.js-ct-code').val();
        var internalName = modal.find('.js-internal-name').val();
        var ctType = $('.hidden-data-block .js-ct-type').val() ? 'source_time' : null
        var idAlias = $('.hidden-data-block .js-id-alias').val();
        $.ajax({
            url: "/worker_charges/delete_construction_manual",
            dataType: "json",
            method: "POST",
            data: {
                id_alias: idAlias, worker_id: workerId, worker_name: workerName, ct_type: ctType,
                construction_date: constructionDate, ct_code: ctCode, name_internal: internalName
            },
        }).done(function (response, status, xhrOpts) {
            location.reload();
        }).fail(function (err) {});
    });
});

function getConstructionSelectedOptions() {
    var constructionSelectedOptions = localStorage.getItem("constructionSelectedOptions");
    if (constructionSelectedOptions != undefined) {
        $.each(JSON.parse(constructionSelectedOptions), function (index, value) {
            $('.js-worker-construction-code').append($('<option>', {
                value: value.id,
                text: value.text,
                selected: true
            })).trigger("change");
        });
    }
    ;
}

function getAreaIdSelectedOptions() {
    var areaSelectedOptions = localStorage.getItem("areaSelectedOptions");
    if (areaSelectedOptions != undefined) {
        $.each(JSON.parse(areaSelectedOptions), function (index, value) {
            $(`.worker_charge_area_id option[value=${value.id}]`).attr('selected', true).trigger("change");
        });
    }
    ;
}
