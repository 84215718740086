$(function(){
  $(".estimate-show-page .estimates-content-group span.datepicker-trigger").removeClass("datepicker-trigger");
  $(".estimate-show-page .estimates-content-group .back-btn").prop("disabled", false);

  $(document).on("click", ".file_label", function() {
    $(this).closest("div.file-box").find("input[type=file]").trigger("click");
  });
  $(document).on("change", "input[type=file]", function() {
    $(this).closest("div.nested-fields").find(".file_name").text($(this).val().replace(/C:\\fakepath\\/i, ""));
    $(this).closest("div.nested-fields").find("div.col-md-6").addClass("div-after-insert");
  });

  $(document).on("click", ".file-wrapper .remove_fields", function() {
    $(this).closest("div.file-wrapper").hide();
  });

  $(".whole_type_small_shaft:first").closest(".nested-fields").find(".remove_fields:first").addClass("hidden");
  $(".whole_type_circular_lining:first").closest(".nested-fields").find(".remove_fields:first").addClass("hidden");

  $(".datepicker-trigger").click(function () {
    $(this).closest("div").find("input.input-date-picker").focus();
  });

  $(".filter-datepicker-trigger").click(function () {
    $(this).closest("div").find("input.date-picker").focus();
    $(this).closest("div").find("input.month-picker").focus();
  });

  $(".select-file-btn").on("click", function () {
    $(".file-selection").trigger("click");
  });

  $(".file-selection").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(".filename-selected").text(fileName);
  });
});