import $ from "jquery"
import "select2"
import "select2/dist/css/select2.css"

$(function () {
  $.fn.select2.amd.define("select2/i18n/ja",[],require("select2/src/js/select2/i18n/ja"));
  
  $(".togglemenu").click(function () {
    $(".sidebar").toggleClass("sidebarhidden");
    $(".right-sec").toggleClass("sidebarhidden");
    if ($(".sidebar").hasClass("sidebarhidden")) {
      $(".construction-group").addClass("sidebar-collapse");
      $(".js-construction-list").removeClass("fix-width");
      $(".controls-row").removeClass("fix-width");
      $("#formDataConstruction").removeClass("fix-form-search");
      $("#construction_tab").removeClass("sidebar-hidden");
    } else {
      $(".construction-group").removeClass("sidebar-collapse");
      $(".js-construction-list").addClass("fix-width");
      $(".controls-row").addClass("fix-width");
      $("#formDataConstruction").addClass("fix-form-search");
      $("#construction_tab").addClass("sidebar-hidden");
    }
  });
  // $(".collapsed").addClass("sidebarhidden");

  $(".input-select2").select2({
    templateSelection: function (data) {
      if (data.id === "") { // adjust for custom placeholder values
        return "検索";
      }

      return data.text;
    }
  });

  $(".select2").select2({
    width: "resolve",
    closeOnSelect : false,
    allowHtml: true,
    language: "ja"
  });

  $(".worker-selection").on("select2:open", function (e) {
    $("input.select2-search__field").prop("placeholder", "入力名");
  });

  var createdWorkerCode = $("#estimate_created_worker_code").val();
  $(".created_worker_code_selection").val(createdWorkerCode).trigger("change");

  var estimateWorkerCode = $("#estimate_estimate_worker_code").val();
  $(".estimate_worker_code_selection").val(estimateWorkerCode).trigger("change");

  var businessWorkerCode = $("#estimate_business_worker_code").val();
  $(".business_worker_code_selection").val(businessWorkerCode).trigger("change");

  $(".created_worker_code_selection").on("select2:close", function () {
    $("#estimate_created_worker_code").val($(this).find("option:selected").text());
  });

  $(".estimate_worker_code_selection").on("select2:close", function () {
    $("#estimate_estimate_worker_code").val($(this).find("option:selected").text());
  });

  $(".business_worker_code_selection").on("select2:close", function () {
    $("#estimate_business_worker_code").val($(this).find("option:selected").text());
  });

  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }
  $("html").scrollTop(0);

  $("input.auto-commas").keyup(function (event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;

    // format number
    $(this).val(function (index, value) {
      return value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });
    $(this).val("￥" + $(this).val());
  });

  $("input.commas-without-icon").keyup(function (event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;

    // format number
    $(this).val(function (index, value) {
      return value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });
  });

  $(".js-submit_btn").prop("disabled", true);
  $(".js-page_input").keyup(function (event) {
    if (event.which >= 37 && event.which <= 40) return;

    var pageValue = $(this).val();
    var pageValid = $.isNumeric(pageValue) && pageValue > 0 ? true : false;

    if (pageValid){
      $(".js-submit_btn").prop("disabled", false);
      $(this).removeClass("border-red");
    } else {
      $(".js-submit_btn").prop("disabled", true);
      $(this).addClass("border-red");
    }
  });
});
