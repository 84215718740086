import $ from "jquery";
import "bootstrap";
import "select2";
import "select2/dist/css/select2.css";

$(document).ready(function () {
  var selectedWorker = localStorage.getItem("workerSelected");

  if (selectedWorker != null && selectedWorker != "") {
    var workerSplit = selectedWorker.split(",");
    var inputCheckbox = $(".worker-check-td input.workers-check");
    $.each(inputCheckbox, function (index, value) {
      var tData = $(this).closest("tr").find(".td_worker_name").text();
      var isWorkerExists = workerSplit.find(x => x === tData);
      if (isWorkerExists != undefined) {
        $(this).prop("checked", true);
      }
    });

    var workerSelectedOptions = [];
    $.each(workerSplit, function (index, value) {
      workerSelectedOptions.push({id: value, text: value});
    });

    workerSelectedOptions = [{id: selectedWorker, text: "全て"}].concat(workerSelectedOptions);

    // Remove select2 value and add new options
    $(".worker-selection").html("").select2({data: workerSelectedOptions});
  } else if (selectedWorker == "") {
    $("#workers-schedule-list tbody tr").each(function(){
      $(this).removeClass("hidden");
    });
  }
});

$(function () {
  $(".check-all").click(function () {
    var checkBoxes = $("input.workers-check");
    var isChecked = $(this).prop("checked");
    checkBoxes.prop("checked", isChecked);
  });

  $(document).on("click", ".export-excel-btn.workload", function() {
    var params = $(this).data("params");
    var url = $(this).data("url");
    var workerSearchParam = "";
    var workerList = localStorage.getItem("workerSelected");
    var workerFromDropDown = $(".worker-selection").val();

    if (!_.isEmpty(workerFromDropDown)) {
      workerSearchParam = workerFromDropDown;
    } else if (workerList) {
      workerSearchParam = workerList
    }
    if (!_.isEmpty(workerSearchParam)) url += `&chart_worker_name=${workerSearchParam}`;

    window.location.href = url;
  });

  $(".workers-list tbody tr td.schedule-has-value").on("click", function () {
    if($(this).find("span.construction_code_date").length !== 0) {
      var valueEl = $(this).closest("tr").find(".w-data");
      var linkType = $(valueEl).attr("linkType");
      var workerName = $(valueEl).attr("worker-name");
      var constructionData = $(this).find(".construction_code_date").text();
      var monthDays = $(valueEl).attr("month-days");
      $(".link-type").val(linkType);
      var constructionCodeTitle = linkType == "estimates" ? "見積No" : "工事番号";
      $(".construction_code_title").text(constructionCodeTitle);
      $("#constructionDataModal").modal("show");
      $("#constructionDataModal .redirect_page_data").empty();

      if (linkType == "estimates") {
        $(".construction_assigned_role").hide();
        $(".load_factor").hide();
      } else {
        $(".construction_assigned_role").show()
        $(".load_factor").show();
      }

      var tdStr = "";
      if (constructionData != "") {
        var dataArray = constructionData.split("/");
        $.each(dataArray, function (index, data) {
          var splitData = data.split("||");
          var constructionCodeFirst = splitData[0].split("-")[0];

          if (!constructionCodeFirst || (_.toInteger(constructionCodeFirst) == 0)) splitData.unshift("");
          var constructionName = splitData[1];
          var constructionCodeList = splitData[0];
          var codeSplit = constructionCodeList.split("&");
          var loadFactor = _.isEmpty(splitData[3]) ? "" : splitData[3];
          var parts = codeSplit[0].split("-");
          tdStr += `<tr><td class="construction_code js-construction_code_click" month-days="${monthDays}" worker-name="${workerName}" code="${codeSplit[1]}" method="${parts[1]}" branch="${parts[2]}">${codeSplit[0]}</td>
                      <td class="construction_name js-construction_name_click">${constructionName}</td>`;
          if (splitData.length > 2) {
            tdStr += `<td class="js-assigned_role_click">${splitData[2]}</td>
                      <td class="working_load working_load-editable">${loadFactor}</td>`;
          }
          tdStr +=`</tr>`;
        });
        $("#constructionDataModal .redirect_page_data").append(tdStr);
      }
    };
  });

  $("#constructionDataModal").on("click", "td.js-construction_code_click, td.js-construction_name_click, td.js-assigned_role_click", function () {
    var parentEl = $(this).closest("tr");
    var constructionCodeEl = parentEl.find("td.construction_code");
    var constructionCode = constructionCodeEl.attr("code");
    var methodCode = constructionCodeEl.attr("method");
    var branchCode = constructionCodeEl.attr("branch");
    var constructionName = parentEl.find("td.construction_name").text();
    var linkType = $(".link-type").val();
    var paramType = linkType == "estimates" ? "code" : "estimate_code";
    var nameType = linkType == "estimates" ? "construction_name" : "name_internal";
    $(".loading-show").show();
    window.location.href = `/${linkType}?${paramType}=${constructionCode}&method_code=${methodCode}&branch_code=${branchCode}&${nameType}=${constructionName}`;
  });

  var checkDateEl = $(".years-filter input.check-text");
  checkDateEl.keyup(function (event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;

    var monthValue = $(".years-filter #month_input").val();
    var yearValue = $(".years-filter #year_input").val();
    var monthValid = $.isNumeric(monthValue) && monthValue <= 12 && monthValue > 0 ? true : false;
    var yearValid = $.isNumeric(yearValue) && yearValue >= 2000 && yearValue < 3999 && yearValue > 0 ? true : false;

    if (monthValid && yearValid){
      $(".search-btn").prop("disabled", false);
      checkDateEl.removeClass("border-red");
    } else {
      $(".search-btn").prop("disabled", true);
      checkDateEl.addClass("border-red");
    }
  });

  $(".btn-filter-chart").on("click", function(){
    var workersList = [];
    var workersUncheckList = [];
    var workerSelectedOptions = [];
    var workersChecked = $(".worker-check-td input.workers-check:checked");
    if (workersChecked.length != 0) {
      $.each(workersChecked, function (index, value) {
        var workerSelected = $(this).closest("tr").find(".td_worker_name").text();
        workersList.push(workerSelected);
        workerSelectedOptions.push({id: workerSelected, text: workerSelected});
      });
      workerSelectedOptions = [{id: workersList.join(), text: "全て"}].concat(workerSelectedOptions);
    } else {
      $.each($(".td_worker_name"), function (index, value) {
        var workerName = $(this).text();
        workersUncheckList.push(workerName);
        workerSelectedOptions.push({id: workerName, text: workerName});
      });
      workerSelectedOptions = [{id: workersUncheckList.join(), text: "全て"}].concat(workerSelectedOptions);
    }

    var headerHeight = $("header").outerHeight();
    var filterGroupHeight = $(".filters-group").outerHeight();
    var controlBoxHeight = $(".controls-workers").outerHeight();
    var workerTableDescription = $(".worker-table-description").outerHeight();
    var minusHeight = headerHeight + filterGroupHeight + controlBoxHeight + workerTableDescription + 20;

    if (workersList.length == 0) {
      $("#workers-schedule-list tbody tr, #workers-list tbody tr").removeClass("disappear");

      $(".worker-table-wrapper").css("height", `calc(100vh - ${minusHeight}px`);
      $(".worker-schedule-wrapper").css("height", `calc(100vh - ${minusHeight}px`);
    } else {
      $("#workers-schedule-list tbody tr, #workers-list tbody tr").each(function(){
        if($.inArray($(this).attr("worker-name"), workersList) !== -1) {
          $(this).removeClass("disappear");
        } else {
          $(this).addClass("disappear");
        }
      });

      var workerHeight = $("#workers-list").outerHeight();
      $("#workers-schedule-list").css("height", workerHeight);
      if (workerHeight < 500) {
        $(".worker-table-wrapper").css("height", `${workerHeight + 10}px`);
        $(".worker-schedule-wrapper").css("height", `${workerHeight + 10}px`);
      } else {
        $(".worker-table-wrapper").css("height", `calc(100vh - ${minusHeight}px`);
        $(".worker-schedule-wrapper").css("height", `calc(100vh - ${minusHeight}px`);
      }
    };

    renderChart(workersList.join());

    // Remove select2 value and add new options
    $(".worker-selection").html("").select2({data: workerSelectedOptions});

    localStorage.setItem("workerSelected", workersList);
  });

  $(".worker-selection").on("select2:selecting", function (e) {
    renderChart(e.params.args.data.id);
  });

  $(document).on("click", "a, input[type=submit]", function(){
    if (!$(this).hasClass("workload-keep-storage")) {
      localStorage.removeItem("workerSelected");
      localStorage.removeItem("chartsData");
    }
  });

  $(".int-field").keyup(function (e) {
    if (/\D/g.test(this.value)) {
      // $(this).closest(".int-box").find(".err-msg").html("整数をご入力ください。").show().delay(1000).fadeOut("slow");
      this.value = this.value.replace(/\D/g, '');
    }
  });

  function renderChart(workerName) {
    $(".loading-show").show();
    var uri = $(".query_params").val();
    $.ajax(uri, {
      type: "GET",
      data: {chart_worker_name: workerName},
      success: function (data, status, xhr) {
        $("#workloadChart").remove();
        $(".chart-box").append("<canvas id='workloadChart'></canvas>");
        $(".loading-show").hide();
        localStorage.setItem("chartsData", JSON.stringify(data));

        var chartData = data;
        var labels = chartData.totals_charts;
        var estimateAxes = chartData.estimates;
        var constructionAxes = chartData.constructions;
        var ctx = document.getElementById("workloadChart").getContext("2d");
        if (ctx !== null) {
          var chart = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [{
                type: "bar",
                label: "見積",
                backgroundColor: "#17618F",
                data: constructionAxes
              }, {
                type: "bar",
                label: "工事",
                backgroundColor: "#93C2C6",
                barPercentage: 1,
                data: estimateAxes,
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              scales: {
                y: {
                  beginAtZero: true
                },
                xAxes: [{
                  stacked: true,
                  barPercentage: 0.7
                }],
                yAxes: [{
                  stacked: true,
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }
          });
        }
      },
      error: function (jqXhr, textStatus, errorMessage) {
        $(".loading-show").hide();
        alert("Error: " + errorMessage);
      }
    });
  }
});
