import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";

$(function() {
  var MAIN_END_POINT = $(".main-layout-wrap").data("main-endpoint");
  var $statusElm = $("#job-status");
  var MAX_FAILED_REQUEST = 10;
  var failedRequestNumber = 0;
  var $progressElm = $("div[role=progressbar]", $statusElm);

  $(".export-excel-btn.team-management").on("click", function() {
    var params = $("#wrap-group-team-content").data("params");

    $.ajax({
      url: "/team_managements/export",
      dataType: "json",
      data: params,
    })
      .done(function (response, status, xhrOpts) {
        if (status === "success") {
          var jobId = response.jid;
          var intervalName = "job_" + jobId;
          $statusElm.toggleClass("hidden");
          setProgressPercentage($progressElm, "0");
          window[intervalName] = setInterval(function () {
            getJobStatus(jobId, intervalName);
          }, 5000);
        }
      })
      .fail(function (err) {});
  });

  function getJobStatus(jobId, intervalName) {
    var jobStatusUrl = "/team_managements/job_status";
    $.ajax({
      method: "POST",
      url: jobStatusUrl,
      data: {
        job_id: jobId,
      },
    })
      .done(function (response, status, xhrOpts) {
        if (status === "success") {
          var percentage = response.percentage;
          setProgressPercentage($progressElm, percentage || 0);

          if (response.status === "complete") {
            setProgressPercentage($progressElm, "100");
            setTimeout(function () {
              deleteIntervalJob(intervalName);
              setProgressPercentage($progressElm, "0");

              $statusElm.toggleClass("hidden");
              var exportUrl =
                "/team_managements/export_download.xlsx" + "?id=" + jobId + "&export_model=construction_group";

              window.location.href = exportUrl;
            }, 500);
          } else if (
            (response.status === "retrying" || response.status === "queued") &&
            response.percentage == null
          ) {
            failedRequestNumber += 1;
          }
          if (failedRequestNumber >= MAX_FAILED_REQUEST) {
            $statusElm.toggleClass("hidden");
            deleteIntervalJob(intervalName);
          }
        } else {
          deleteIntervalJob(intervalName);
        }
      })
      .fail(function (err) {
        deleteIntervalJob(intervalName);
      });
  }

  $(".js-construction-code").select2({
    ajax: {
      url: `${MAIN_END_POINT}search_construction_fields?pageSearch=team&field=construction_code&current_page=1&per_page=20`,
      dataType: "json",
      quietMillis: 100,
      data: function (params) {
        return {
          keyword: params.term,
        };
      },
      processResults: function (data) {
        return { results: data };
      },
    },
    multiple: true,
    language: "ja",
    closeOnSelect: false,
    tags: true,
    cache: true
  });

  function deleteIntervalJob(intervalName) {
    clearInterval(window[intervalName]);
    delete window[intervalName];
    failedRequestNumber = 0;
  }

  function setProgressPercentage($progressElm, percentage) {
    $progressElm
      .attr("aria-valuenow", percentage)
      .css("width", percentage + "%")
      .text(percentage + "%");
  }

  $(".js-search-team-management").on("click", function() {
    var options = [];
    _.forEach($(".js-construction-code option:selected"), function($selectedOption) {
      options.push({id: $($selectedOption).val(), text: $($selectedOption).text()});
    });

    if (options.length > 0) {
      localStorage.setItem("contructionSelectedOptions", JSON.stringify(options));
    } else {
      localStorage.removeItem("contructionSelectedOptions");
    }
  });

  if ($(".team-management-content").data("is-init")) {
    localStorage.removeItem("contructionSelectedOptions");
  }

  var contructionSelectedOptions = localStorage.getItem("contructionSelectedOptions");
  if (contructionSelectedOptions) {
    var ctOptions = JSON.parse(contructionSelectedOptions);
    _.forEach(ctOptions, function(ctOption) {
      $(".js-construction-code").append($("<option>", {
        value: ctOption.id,
        text : ctOption.text,
        selected: true
      })).trigger("change");
    });
  }

  $('.js-team-clickable').on('click', function(){
    var canManageData = $(this).data('can-manage-data');
    if (!canManageData) {
      return false;
    }

    var teamId = $(this).closest('tr').data('team-id');
    var teamName = $(this).closest('tr').data('team-name');
    var teamCode = $(this).closest('tr').data('team-code');
    var idAlias = $(this).closest('.items-group').data('id-alias');
    var ctCode = $(this).data('ct-code');
    var nameInternal = $(this).data('name-internal');
    var date = $(this).data('date-format');
    var dateFormatJp = $(this).data('date-format-jp');
    var ctType = $(this).closest('.items-group').data('ct-type');
    var parentCtType = $(this).closest('.items-group').data('ct-type');
    if ($(this).hasClass('bg-cyan') || parentCtType == 'cyan'){
      ctType = null;
    }
    var startDateJp = $(this).data('start-date-jp');
    var endDateJp = $(this).data('end-date-jp');
    var teamData = $(this).closest('.item-group-td').data('team-construction');
    var existCtCode = $(this).closest('.items-group').data('ct-code');
    var existNameInternal = $(this).closest('.items-group').data('name-internal');

    $('.hidden-data-block .js-team-id').val(teamId);
    $('.hidden-data-block .js-team-name').val(teamName);
    $('.hidden-data-block .js-team-code').val(teamCode);
    $('.hidden-data-block .js-id-alias').val(idAlias);
    $('.hidden-data-block .js-ct-code').val(ctCode);
    $('.hidden-data-block .js-name-internal').val(nameInternal);
    $('.hidden-data-block .js-ct-date').val(date);
    $('.hidden-data-block .js-ct-date-format-jp').val('').val(dateFormatJp);
    $('.hidden-data-block .js-ct-type').val('').val(ctType);
    $('.hidden-data-block .js-edit-start-at').val('').val(startDateJp);
    $('.hidden-data-block .js-edit-end-at').val('').val(endDateJp);
    $('.hidden-data-block .js-edit-team-data').val('').val(teamData);
    $('.hidden-data-block .js-exist-ct-code').val('').val(existCtCode);
    $('.hidden-data-block .js-exist-name-internal').val('').val(existNameInternal);

    if ($(this).hasClass('bg-yl') || $(this).hasClass('bg-cyan')) {
      if ($(this).hasClass('js-data-manual')) {
        $('#existFieldChoseActionModal').find('.has_data').show();
        $('#existFieldChoseActionModal').find('.has_no_data').hide();
      } else {
        $('#existFieldChoseActionModal').find('.has_data').hide();
        $('#existFieldChoseActionModal').find('.has_no_data').show();
      }
      $('#existFieldChoseActionModal').show();
      $('#existFieldChoseActionModal').css('opacity', 1);
    } else {
      $('#blankFieldChoseActionModal').show();
      $('#blankFieldChoseActionModal').css('opacity', 1);
    }
  });

  $(document).on("click", ".js-btn-create", function (e) {
    e.preventDefault();
    $('.error-msg').hide();

    var workerName = $('.hidden-data-block .js-worker-name').val();
    var workerId = $('.hidden-data-block .js-worker-id').val();
    var dateFormat = $('.hidden-data-block .js-construction-date').val();
    $('#createNewConstructionModal .js-worker-id').val('').val(workerId);
    $('#createNewConstructionModal .js-worker-name').val('').val(workerName);
    $('#createNewConstructionModal .js-construction-date').val('').val(dateFormat);
    $('#createNewConstructionModal .js-ct-start-at-input').val('');
    $('#createNewConstructionModal .js-ct-end-at-input').val('');
    $('#createNewConstructionModal .js-ct-start-at').val('');
    $('#createNewConstructionModal .js-ct-end-at').val('');

    $(this).closest('.modal').hide();
    $('#createNewConstructionModal').show();
    $('#createNewConstructionModal').css('opacity', 1);
  });

  $(document).on("click", ".js-team-btn-submit", function (e) {
    e.preventDefault();
    $(this).addClass('disabled');
    $('input').removeClass('error-input');
    $('.error-msg').hide();
    $('.js-start-at-error').hide();
    $('.js-end-at-error').hide();

    var modal = $('#createNewConstructionModal');
    var constructionCode = modal.find('.js-construction-code').val();
    var internalName = modal.find('.js-internal-name').val();
    var teamId = $('.hidden-data-block .js-team-id').val();
    var teamName = $('.hidden-data-block .js-team-name').val();
    var teamCode = $('.hidden-data-block .js-team-code').val();
    var startAt = modal.find('.js-ct-start-at').val();
    var endAt = modal.find('.js-ct-end-at').val();

    $.ajax({
      url: "/team_managements/create_construction_manual",
      dataType: "json",
      method: "POST",
      data: {
        construction_code: constructionCode, internal_name: internalName, team_id: teamId,
        team_code: teamCode, team_name: teamName, start_at: startAt, end_at: endAt
      },
    }).done(function (response, status, xhrOpts) {
      if (response.hasOwnProperty('construction_code')) {
        $('.js-construction-code').addClass('error-input');
        $('.js-ct-code-error').show();
        $('.js-ct-code-error').html('').html(response.construction_code);
        setTimeout(function() {
          $('.js-ct-code-error').hide();
          $('.js-construction-code').removeClass('error-input');
        }, 3000);
        $('#createNewConstructionModal').find('.js-team-btn-submit').removeClass('disabled');
        return false
      }

      if (response.hasOwnProperty('start_at')) {
        $('.js-ct-start-at-input').addClass('error-input');
        $('.js-start-at-error').show();
        $('.js-start-at-error').html('').html(response.start_at);
        setTimeout(function() {
          $('.js-start-at-error').hide();
          $('.js-ct-start-at-input').removeClass('error-input');
        }, 3000);
        $('#createNewConstructionModal').find('.js-team-btn-submit').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('end_at')) {
        modal.find('.js-ct-end-at-input').addClass('error-input');
        $('.js-end-at-error').show();
        $('.js-end-at-error').html('').html(response.end_at);
        setTimeout(function() {
          $('.js-end-at-error').hide();
          modal.find('.js-ct-end-at-input').removeClass('error-input');
        }, 3000);
        $('#createNewConstructionModal').find('.js-team-btn-submit').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('name_internal')) {
        $('.js-internal-name').addClass('error-input');
        $('.js-name-internal-error').show();
        $('.js-name-internal-error').html('').html(response.name_internal);
        setTimeout(function() {
          $('.js-name-internal-error').hide();
          $('.js-internal-name').removeClass('error-input');
        }, 3000);
        $('#createNewConstructionModal').find('.js-team-btn-submit').removeClass('disabled');
        return false
      }
      $('.js-ct-code-error').hide();
      $('.js-start-at-error').hide();
      $('.js-end-at-error').hide();
      $('.js-name-internal-error').hide();
      location.reload();
    }).fail(function (err) {
      console.log(err);
    });
  });

  // CREATE EXIST CONSTRUCTION
  $(document).on("click", ".js-team-btn-create-exist-confirm", function (e) {
    e.preventDefault();
    $('.error-msg').hide();
    $('.js-exist-start-at-input').val('');
    $('.js-exist-end-at-input').val('');
    $('.js-exist-start-at').val('');
    $('.js-exist-end-at').val('');
    $(this).closest('.modal').hide();
    $('#createExistModal').css('opacity', 1).show();
  });

  $(document).on("click", "#createExistModal .js-team-btn-submit-exists", function (e) {
    e.preventDefault();
    $(this).addClass('disabled');
    $('input').removeClass('error-input');
    $('.error-msg').hide();
    var modal = $('#createExistModal');
    var teamId = $('.hidden-data-block .js-team-id').val();
    var teamName = $('.hidden-data-block .js-team-name').val();
    var teamCode = $('.hidden-data-block .js-team-code').val();
    var ctCode = $('.hidden-data-block .js-exist-ct-code').val();
    var internalName = $('.hidden-data-block .js-exist-name-internal').val();
    var startAt = modal.find('.js-exist-start-at').val();
    var endAt = modal.find('.js-exist-end-at').val();
    var teamData = $('.hidden-data-block .js-edit-team-data').val();
    var currentStartDate = $('.hidden-data-block .js-ct-date').val();
    var ctType = $('.hidden-data-block .js-ct-type').val();
    var idAlias = $('.hidden-data-block .js-id-alias').val();
    $.ajax({
      url: "/team_managements/create_construction_manual",
      dataType: "json",
      method: "POST",
      data: {
        construction_code: ctCode, internal_name: internalName, team_id: teamId, team_code: teamCode,
        team_name: teamName, status: 'exists', start_at: startAt, end_at: endAt, exist: true,
        team_data: teamData, current_start_date: currentStartDate, ct_type: ctType, id_alias: idAlias
      },
    }).done(function (response, status, xhrOpts) {
      if (response.hasOwnProperty('start_at')) {
        $('.js-exist-start-at-input').addClass('error-input');
        $('.js-start-at-error').show();
        $('.js-start-at-error').html('').html(response.start_at);
        setTimeout(function() {
          $('.js-start-at-error').hide();
          $('.js-exist-start-at-input').removeClass('error-input');
        }, 3000);
        $('#createExistModal').find('.js-team-btn-submit-exists').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('end_at')) {
        modal.find('.js-exist-end-at-input').addClass('error-input');
        $('.js-end-at-error').show();
        $('.js-end-at-error').html('').html(response.end_at);
        setTimeout(function() {
          $('.js-end-at-error').hide();
          modal.find('.js-exist-end-at-input').removeClass('error-input');
        }, 3000);
        $('#createExistModal').find('.js-team-btn-submit-exists').removeClass('disabled');
        return false
      }
      $('.js-start-at-error').hide();
      $('.js-end-at-error').hide();
      location.reload();
    }).fail(function (err) {});
  });

  // TEAM EDIT
  $(document).on("click", ".js-team-btn-edit", function (e) {
    e.preventDefault();
    $('input').removeClass('error-input');
    $('.error-msg').hide();
    var ctCode = $('.hidden-data-block .js-ct-code').val();
    var nameInternal = $('.hidden-data-block .js-name-internal').val();
    var startDate = $('.hidden-data-block .js-edit-start-at').val();
    var endDate = $('.hidden-data-block .js-edit-end-at').val();
    $('.js-ct-start-at-input').val('').val(startDate);
    $('.js-ct-end-at-input').val('').val(endDate);
    $('.js-ct-start-at').val('').val(startDate);
    $('.js-ct-end-at').val('').val(endDate);
    $("#editConstructionModal .js-construction-code").find('option')
        .remove().end()
        .append(`<option value=${ctCode}>${ctCode}</option>`)
        .val(ctCode)
        .trigger("change");

    $("#editConstructionModal .js-name-internal").val(nameInternal);
    $(this).closest('.modal').hide();
    $('#editConstructionModal').css('opacity', 1).show();
  });

  $(document).on("click", ".js-team-btn-edit-submit", function (e) {
    e.preventDefault();
    $(this).addClass('disabled');
    $('input').removeClass('error-input');
    $('.error-msg').hide();
    $('.js-start-at-error').hide();
    $('.js-end-at-error').hide();

    var constructionCode = $('#editConstructionModal .js-construction-code').val();
    var teamId = $('.hidden-data-block .js-team-id').val();
    var teamName = $('.hidden-data-block .js-team-name').val();
    var teamCode = $('.hidden-data-block .js-team-code').val();
    var nameInternal = $('#editConstructionModal .js-name-internal').val();
    var startAt = $('#editConstructionModal .js-ct-start-at').val();
    var endAt = $('#editConstructionModal .js-ct-end-at').val();
    var sourceCtCode = $('.hidden-data-block .js-ct-code').val();
    var idAlias = $('.hidden-data-block .js-id-alias').val();
    $.ajax({
      url: "/team_managements/edit_construction_manual",
      dataType: "json",
      method: "POST",
      data: {
        id_alias: idAlias, construction_code: constructionCode, name_internal: nameInternal,
        team_id: teamId, team_name: teamName, team_code: teamCode, start_at: startAt, end_at: endAt,
        source_construction_code: sourceCtCode
      },
    }).done(function (response, status, xhrOpts) {
      if (response.hasOwnProperty('construction_code')) {
        $('.js-construction-code').addClass('error-input');
        $('.js-ct-code-error').show();
        $('.js-ct-code-error').html('').html(response.construction_code);
        setTimeout(function() {
          $('.js-ct-code-error').hide();
          $('.js-construction-code').removeClass('error-input');
        }, 3000);
        $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('start_at')) {
        $('.js-ct-start-at-input').addClass('error-input');
        $('.js-start-at-error').show();
        $('.js-start-at-error').html('').html(response.start_at);
        setTimeout(function() {
          $('.js-start-at-error').hide();
          $('.js-ct-start-at-input').removeClass('error-input');
        }, 3000);
        $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('end_at')) {
        $('#editConstructionModal').find('.js-ct-end-at-input').addClass('error-input');
        $('.js-end-at-error').show();
        $('.js-end-at-error').html('').html(response.end_at);
        setTimeout(function() {
          $('.js-end-at-error').hide();
          $('#editConstructionModal').find('.js-ct-end-at-input').removeClass('error-input');
        }, 3000);
        $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
        return false
      }
      if (response.hasOwnProperty('name_internal')) {
        $('#editConstructionModal').find('.js-internal-name').addClass('error-input');
        $('.js-name-internal-error').show();
        $('.js-name-internal-error').html('').html(response.name_internal);
        setTimeout(function() {
          $('.js-name-internal-error').hide();
          $('#editConstructionModal').find('.js-internal-name').removeClass('error-input');
        }, 3000);
        $('#editConstructionModal').find('.js-btn-edit-submit').removeClass('disabled');
        return false
      }
      $('.js-start-at-error').hide();
      $('.js-end-at-error').hide();
      $('.js-name-internal-error').hide();
      location.reload();
    }).fail(function (err) {
      console.log(err);
    });
  });

  // TEAM DELETE
  $(document).on("click", ".js-team-btn-delete", function (e) {
    e.preventDefault();

    var teamName = $('.hidden-data-block .js-team-name').val();
    var ctCode = $('.hidden-data-block .js-ct-code').val();
    var dateFormatJp = $('.hidden-data-block .js-ct-date-format-jp').val();
    // var teamId = $('.hidden-data-block .js-team-id').val();
    // var teamCode = $('.hidden-data-block .js-team-code').val();
    // var dateFormat = $('.hidden-data-block .js-ct-date').val();
    // var internalName = $('.hidden-data-block .js-internal-name').val();

    var confirmText = `${teamName}の${dateFormatJp}の${ctCode}を削除してもよろしいでしょうか。`
    $('#confirmDeleteModal .confirm-text').html(confirmText);
    $(this).closest('.modal').hide();
    $('#confirmDeleteModal').show();
    $('#confirmDeleteModal').css('opacity', 1);
  });

  $(document).on("click", ".js-team-btn-delete-ok", function (e) {
    e.preventDefault();

    var teamId = $('.hidden-data-block .js-team-id').val();
    var teamName = $('.hidden-data-block .js-team-name').val();
    var teamCode = $('.hidden-data-block .js-team-code').val();
    var ctDate = $('.hidden-data-block .js-ct-date').val();
    var ctCode = $('.hidden-data-block .js-ct-code').val();
    var nameInternal = $('.hidden-data-block .js-name-internal').val();
    var ctType = $('.hidden-data-block .js-ct-type').val() ? 'source_time' : null
    var idAlias = $('.hidden-data-block .js-id-alias').val()
    $.ajax({
      url: "/team_managements/delete_construction_manual",
      dataType: "json",
      method: "POST",
      data: {
        id_alias: idAlias, team_id: teamId, team_name: teamName, team_code: teamCode, ct_type: ctType,
        construction_date: ctDate, ct_code: ctCode, name_internal: nameInternal
      },
    }).done(function (response, status, xhrOpts) {
      location.reload();
    }).fail(function (err) {});
  });
});
