import $ from "jquery";

$(function() {
  $(".js-btn-create").on("click", function() {
    $("#modal-create-teams").modal("show");
  })

  $(".btn-delete").on("click", function() {
    var teamId = $(this).data("team-id");
    $("#delete-confirm-modal").data("team-id", teamId);
    $("#delete-confirm-modal").modal("show");
  })

  $(".js-btn-confirm-delete-team").on("click", function() {
    var teamId = $("#delete-confirm-modal").data("team-id");

    $.ajax({
      method: "DELETE",
      url: `/teams/${teamId}`
    })
  });

  $(".js-save-team-showable").on("click", function() {
    var teamIds = $("table.content-team__body").data("team-ids");
    var checkedIds = _.compact(_.map($(".team-check"), function($elm) {
      return $elm.checked ? $($elm).data("team-id") : null;
    }));
    $.ajax({
      method: "PUT",
      url: "/teams/update_showable",
      dataType: "json",
      data: {
        "team_ids": teamIds,
        "showable_ids": checkedIds
      },
      success: function(res) {
        window.location.reload();
      }
    })
  })
});
